@import "_variables";

// break popint
$SPblake: 767px;
$TABblake: $SPblake+1;
$PCblake: 940px;
$BIGblake: 1024px;

@mixin max-screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}
@mixin min-screen($break-point) {
	@media screen and (min-width: $break-point) {
		@content;
	}
}
@mixin screen($break-point-min, $break-point-max) {
	@media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
		@content;
	}
}

//@at-root　階層かくし
@mixin atroot($selecter){
	@at-root{
		#{$selecter}{
			@content;
		}
	}
}

//selectparts
@mixin label_radio_checkbox($kind:"checkbox",$color:#f00){
	margin-right: 40px;
	padding-left: 24px;
	display: inline-block;
	position: relative;
	font-size: 16px;
	color: #000;
	@include max-screen($SPblake){
		display: block;
		margin-right: 0;
		padding: {
			left: 34px;
			top: 14px;
			bottom: 14px;
			right: 14px;
		}
		border: solid 1px #bcbcbc;
	}
	&:before{
		width: 14px;
		height: 14px;
		border: solid 1px #bcbcbc;
		box-sizing: border-box;
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		content: "";
		transform: translate(0,-50%);
		@include max-screen($SPblake){
			left: 10px;
		}
	}
	&:after{
		position: absolute;
		display: block;
		content: "";
	}
	&:hover{
		&:before{
			border-color: $color;
		}
	}
	&.disabled{
		color: #bcbcbc;
		@include max-screen($SPblake){
			display: none;
		}
	}
	&.checked{
		&:before{
			border-color: $color;
		}
		&:after{}
	}
	input{
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	@if $kind == "radio"{
		&:before{
			border-radius: 100%;
		}
		&:after{
			background: #bcbcbc;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			top: 50%;
			left: 4px;
			transform: translate(0,-50%);
			@include max-screen($SPblake){
				left: 14px;
			}
		}
		&.checked{
			&:after{
				background: $color;
			}
		}
	}@else if $kind == "checkbox"{
		&:after{
			width: 10px;
			height: 6px;
			box-sizing: border-box;
			border: solid 2px;
			border-color: transparent;
			top: 50%;
			left: 1px;
			transform-origin: left top;
			transform: rotate(-45deg);
			@include max-screen($SPblake){
				left: 11px;
			}
		}
		&.checked{
			&:after{
				border-color: transparent transparent $color $color;
			}
		}
	}
}
//サイト固有のミックスイン
