@mixin keyframes($AnimName: animation){
	@-webkit-keyframes #{$AnimName}{
    @content;
  }
  @-moz-keyframes #{$AnimName}{
    @content;
  }  
  @keyframes #{$AnimName}{
    @content;
  }
}
@mixin animation($AnimName){
  -webkit-animation: $AnimName;
  -moz-animation: $AnimName;
  animation: $AnimName;
}

// 以下アニメーション
@include keyframes(fadeIn){
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}
@include keyframes(fadeInUp){
	0%{
		opacity:0;
		transform:translateY(10px);
	}
	80%{
		transform:translateY(0px);
	}
	100%{
		opacity:1;
		transform:translateY(0px);
	}
}

// 使うとき用のメモ
// @include animation(fade 1s ease 0.1s);

/* --------------------------------------------------- */

.heroBG{
	opacity:0;
	@include animation(fadeIn 3s cubic-bezier(.5,.7,0,1) 0.2s forwards);
}

/* svg -----------------------------------------------*/

.heroV{
	#milksvg .st0{
		fill:none;
		stroke:#ffffff;
		stroke-width:600;
		stroke-dasharray: 2303, 2305;
		stroke-dashoffset: 2304;
	}
	#sourcesvg .st0{
		fill:none;
		stroke:#ffffff;
		stroke-width:88;
		stroke-dasharray: 6986, 6988;
		stroke-dashoffset: 6987;
	}
	.drink{
		opacity:0;
	}
	.milk_ov{
		opacity:0;
	}
	.berry{
		opacity:0;
	}
	.berry_juice{
		opacity:0;
	}
	&.start{
		#milksvg .st0{
			transition:cubic-bezier(.7,.1,.3,1) 1s stroke-dashoffset;
			stroke-dashoffset: 0;
		}
		#sourcesvg .st0{
			transition:1.4s cubic-bezier(.3,0,.7,1) 1.4s stroke-dashoffset;
			stroke-dashoffset: 0;
		}
		.drink{
			@include animation(fadeIn .8s cubic-bezier(.5,.7,0,1) .8s forwards);
		}
		.milk_ov{
			@include animation(fadeIn 0.1s linear .7s forwards);
		}
		.berry{
			@include animation(fadeIn .6s cubic-bezier(.5,.7,0,1) 1s forwards);
		}
		.berry_juice{
			@include animation(fadeIn .4s cubic-bezier(.5,.7,0,1) 1.3s forwards);
		}
	}
}

.heroMain{
	h1{
		opacity:0;
		transform:translateY(10px);
	}
	#numsvg .st0{
		fill:none;
		stroke:#ffffff;
		stroke-width:11;
		stroke-dasharray: 4170, 4172;
		stroke-dashoffset: 4171;
	}
	&.start{
		h1{
			@include animation(fadeInUp cubic-bezier(.5,.3,0,1) 1.6s forwards);
		}
		#numsvg .st0{
			transition:3s cubic-bezier(.16,0,.9,1) .5s stroke-dashoffset;
			stroke-dashoffset: 0;
		}
	}
}
.profile{
	opacity:0;
	visibility:hidden;
	transition:cubic-bezier(.3,.5,0,1) .8s transform,ease-out .8s opacity;
	&.show{
		opacity:1;
		visibility:visible;
	}
}

.ie{
	display:none;
}

//IE11 / IE10
@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
	
	.ie{
		display:block !important;
	}
	.heroV{
		#milksvg,#sourcesvg{
			&:not(:target){
				display:none;
			}
		}
		.milk,.source{
			&:not(:target){
				opacity:0;
			}
		}
		&.start{
			.milk:not(:target){
				@include animation(fadeIn cubic-bezier(.5,.7,0,1) 1s forwards);
			}
			.source:not(:target){
				@include animation(fadeIn 1.2s cubic-bezier(.5,.7,0,1) 1.6s forwards);
			}
		}
	}
	.heroMain{
		#numsvg:not(:target){
			display:none;
		}
		.num:not(:target){
			opacity:0;
		}
		&.start{
			.num:not(:target){
				@include animation(fadeInUp 1.4s cubic-bezier(.5,.3,0,1) .5s forwards);
			}
		}
	}
}//IE Hack














