//カラー

//フォント指定
$font_yumin: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$font_yugo: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font_yugo_bold: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;

//アイコンフォント
/*@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icon_otherwindow: "\e900"; //別窓表示
$icon_arrow_bottom: "\e901"; //下向き矢印
$icon_arrow_left: "\e902"; //左向き矢印
$icon_arrow_right: "\e903"; //右向き矢印
$icon_arrow_top: "\e904"; //上向き矢印
$icon_plus: "\e906"; //プラスアイコン
$icon_minus: "\e905"; //マイナスアイコン
$icon_twitter: "\e909"; //ツイッターアイコン
$icon_fb: "\e907"; //フェイスブックアイコン
$icon_instagram: "\e908"; //インスタグラムアイコン*/

