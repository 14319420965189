@charset "utf-8";
@import "_format";
@import "_extend";
/* ********************************************************
	add style CSS
******************************************************** */
*{
	box-sizing:border-box;
}
body{
	font-size:14px;
	line-height:150%;
	font-family:$font_yugo_bold;
}
a{
	text-decoration:none;
}
img{
	width:100%;
	height:auto;
}

.contents{
	width:100%;
	overflow:hidden;
}

.sp{
	display:none;
}
@include max-screen($SPblake){
	.pc{
		display:none;
	}
	.sp{
		display:block;
	}
}

/* ------------------------------*/
.header{
	position:absolute;
	top:0;
	left:0;
	right:0;
	width:100%;
	z-index:10;
	&Inner{
		width:95%;
		margin:0 auto;
		padding:10px 0;
		text-align:right;
		@include max-screen($SPblake){
			padding:5px 0;
		}
	}
}
.lang{
	font-size:0;
	display:inline-block;
	li{
		display:inline-block;
		vertical-align:top;
		+li{
			margin-left:10px;
			@include max-screen($SPblake){
				margin-left:5px;
			}
		}
		a{
			background:#000000;
			display:block;
			height:28px;
			width:40px;
			padding-top:4px;
			text-align:center;
			font-size:16px;
			color:#ffffff;
			transition:ease-out .14s opacity;
			@include max-screen($SPblake){
				font-size:13px;
				height:22px;
				width:32px;
				padding-top:1px;
			}
			&:hover{
				opacity:0.6
			}
		}
	}
}

/*-------------------------------
  Contents
-------------------------------*/

.hero{
	width:100%;
	position:relative;
	max-height:860px;
	height:100vh;
	min-height:640px;
	>div{
		pointer-events:none;
	}
	&BG{
		width:100%;
		height:100%;//100vh
		min-height:600px;
		position:relative;
		top:0;
		left:0;
		right:0;
		bottom:0;
		margin:auto;
		z-index:0;
		@include max-screen($SPblake){
			background:url("../images/index_sp.jpg")no-repeat center center/cover;
		}
		>div{
			position:absolute;
			margin:auto;
			background:no-repeat center center/100% auto;
			z-index:1;
			transform:translate(-50%);
			@include max-screen($SPblake){
				display:none;
			}
		}
		.itemLee{
			background-image:url("../images/item_lee.png");
			width:30%;
			height:calc(30vw * 0.804);
			top:-10%;
			left:8%;
		}
		.itemMuscat{
			background-image:url("../images/item_muscat.png");
			width:26%;
			height:calc(26vw * 0.915);
			top:-15%;
			left:42%;
		}
		.itemBlueberry{
			background-image:url("../images/item_blueberry.png");
			width:15%;
			height:calc(15vw * 1.111);
			top:-4%;
			right:16%;
		}
		.itemBerry{
			background-image:url("../images/item_berry.png");
			width:24%;
			height:calc(24vw * 0.944);
			top:8%;
			right:-17%;
		}
		.itemPear{
			background-image:url("../images/item_pear.png");
			width:27%;
			height:calc(27vw * 0.597);
			top:50%;
			left:8%;
		}
		.itemApple{
			background-image:url("../images/item_apple.png");
			width:24%;
			height:calc(24vw * 0.725);
			bottom:-15%;
			left:20%;
		}
		.itemLemon{
			background-image:url("../images/item_lemon.png");
			width:17%;
			height:calc(17vw * 0.952);
			bottom:-18%;
			left:48%;
		}
		.itemCookie{
			background-image:url("../images/item_cookie.png");
			width:28%;
			height:calc(28vw * 0.657);
			bottom:-25%;
			right:-10%;
		}
	}
	&V{
		position:absolute;
		top:0;
		left:0;
		right:0;
		margin:auto;
		width:90%;
		height:100%;
		z-index:1;
		@include max-screen($SPblake){
			width:100%;
		}
		>div{
			position:absolute;
			margin:auto;
			right:0;
			transform:translateX(50%);
			width:833px;
			right:26%;
			height:100%;
			@include max-screen($SPblake){
				width:100%;
				transform:none;
				height:auto;
				right:0;
			}
			img,svg{
				height:95%;
				width:auto;
				@include max-screen($SPblake){
					width:100%;
					height:auto;
				}
			}
		}
		.source{}
		.milk{}
		.drink{}
		.berry{}
		.milk_ov{}
		.berry_juice{}
	}
	&Main{
		max-width:1030px;
		width:80%;
		margin:auto;
		position:absolute;
		top:46%;
		transform:translateY(-50%);
		left:0;
		right:0;
		z-index:2;
		@include max-screen($SPblake){
			height:100%;
			top:0;
			transform:none;
		}
		h1{
			max-width:645px;
			width:65%;
			@include max-screen($SPblake){
				width:90%;
				margin-top:40px;
				margin-left:20px;
			}
		}
		.num{
			margin-top:60px;
			margin-left:5%;
			max-width:295px;
			width:32%;
			@include max-screen($SPblake){
				width:55%;
				position:absolute;
				bottom:23%;
			}
			svg{
				width:100%;
				height:auto;
			}
		}
	}
}

.profile{
	position:fixed;
	background:#000000;
	top:0;
	bottom:0;
	left:50px;
	margin:auto;
	z-index:10;
	width:93%;
	height:80%;
	min-height:500px;
	max-height:700px;
	transform:translateX(-100%);
	transition:cubic-bezier(.3,.5,0,1) .8s transform;
	@include max-screen($BIGblake){
		width:96%;
	}
	@include max-screen($SPblake){
		width:100%;
		left:30px;
		height:auto;
		max-height:calc(100% - 60px);
		min-height:0;
	}
	&Ttl{
		margin:-46px auto auto;
		position:absolute;
		top:0;
		left:-11%;
		right:0;
		max-width:1000px;
		width:85%;
		@include max-screen($SPblake){
			left:-8%;
			margin-top:-24px;
		}
		h2{
			width:280px;
			@include max-screen($SPblake){
				width:170px;
			}
		}
	}
	&Bar{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		right:0;
		width:50px;
		height:100%;
		cursor:pointer;
		@include max-screen($SPblake){
			width:30px;
		}
		p{
			line-height:50px;
			writing-mode: vertical-rl;
			color:#ffffff;
			font-size:16px;
			height:100%;
			text-align:center;
			@include max-screen($SPblake){
				line-height:30px;
				font-size:14px;
			}
			&:after{
				content:"";
				display:inline-block;
				width:14px;
				height:14px;
				border-top:1px solid #ffffff;
				border-right:1px solid #ffffff;
				transform:scaleY(0.8) rotate(45deg);
				margin-left:-10px;
				margin-top:20px;
				@include max-screen($SPblake){
					width:10px;
					height:10px;
				}
			}
		}
		&:after{
			content:"";
			display:block;
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			margin:auto;
			background:rgba(255,255,255,0.3);
			transition:ease-out .14s opacity;
			opacity:0;
		}
		&:hover{
			&:after{
				opacity:1;
			}
		}
	}
	.close{
		width:60px;
		height:60px;
		position:absolute;
		top:0;
		right:0;
		cursor:pointer;
		opacity:0;
		visibility:hidden;
		&:before,&:after{
			content:"";
			display:block;
			background:#ffffff;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			margin:auto;
			width:27px;
			height:1px;
		}
		&:before{
			transform:rotate(45deg);
		}
		&:after{
			transform:rotate(-45deg);
		}
	}
	&Area{
		color:#ffffff;
		font-size:18px;
		display:flex;
		display:-ms-flexbox;
		position:relative;
		margin:0 auto;
		max-width:1000px;
		width:85%;
		top:50%;
		transform:translateY(-50%);
		@include max-screen($BIGblake){
			font-size:16px;
		}
		@include max-screen($SPblake){
			display:block;
			width:70%;
			font-size:13px;
			overflow:scroll;
			padding-top:60px;
			top:0;
			transform:none;
			height:100%;
			padding-bottom:30px;
		}
		table{
			width:calc(97% - 430px);
			margin-right:3%;
			@include max-screen($BIGblake){
				width:calc(97% - 380px);
			}
			@include max-screen($PCblake){
				width:calc(97% - 320px);
			}
			@include max-screen($SPblake){
				width:100%;
				margin-bottom:15px;
			}
			.col1{
				width:130px;
				@include max-screen($SPblake){
					width:85px;
				}
			}
			th,td{
				padding:10px 0;
				line-height:150%;
				@include max-screen($SPblake){
					padding:7px 0;
				}
			}
		}
		.map{
			width:430px;
			@include max-screen($BIGblake){
				width:380px;
			}
			@include max-screen($PCblake){
				width:320px;
			}
			@include max-screen($SPblake){
				width:100%;
				height:70vw;
			}
			iframe{
				width:100%;
				height:100%;
			}
		}
	}
	&.open{
		transform:translateX(-50px);
		@include max-screen($SPblake){
			transform:translateX(-30px);
		}
		.profileBar{
			opacity:0;
			visibility:hidden;
		}
		.close{
			opacity:1;
			visibility:visible;
		}
	}
}

.contentsBG{
	background:url(../images/bg.jpg)no-repeat top center/cover;
	overflow:hidden;
	@include max-screen($SPblake){
		background-image:url("../images/bg_sp.jpg");
	}
}

.section{
	position:relative;
	margin:140px 0;
	@include max-screen($PCblake){
		margin:30px 0;
	}
	&Inner{
		max-width:1000px;
		margin:0 auto;
		width:90%;
		position:relative;
	}
	&Ttl{
		width:400px;
		background:#511322;
		padding:30px 40px 35px;
		min-height:380px;
		text-align:center;
		position:relative;
		z-index:1;
		@include max-screen($SPblake){
			width:85%;
			margin-left:auto !important;
			margin-right:auto !important;
			min-height:275px;
			padding:30px;
		}
		&Head{
			position:relative;
			margin-bottom:30px;
			@include max-screen($SPblake){
				margin-bottom:20px;
			}
			.label{
				position:relative;
				z-index:1;
				height:64px;
				margin-left:-40px;
				text-align:center;
				@include max-screen($SPblake){
					height:40px;
					margin-left:-30px;
				}
				span{
					display:inline-block;
				}
			}
			.img{
				position:absolute;
			}
		}
		h2{
			color:#ffffff;
			font-size:24px;
			line-height:160%;
			font-feature-settings: "palt";
			letter-spacing:1px;
			min-height:150px;
			display:inline-flex;
			justify-content:flex-start;
			align-items: center;
			text-align:left;
			@include max-screen($SPblake){
				font-size:18px;
				min-height:120px;
			}
		}
	}
	&Body{
		background:rgba(30,30,30,.5);
		min-height:350px;
		width:calc(100% - 60px);
		position:absolute;
		bottom:-60px;
		right:0;
		z-index:0;
		display:flex;
		justify-content:flex-end;
		align-items:center;
		padding:0 60px;
		transform:scaleX(0);
		transform-origin:left;
		transition:cubic-bezier(.3,.5,0,1) .5s transform, .3s ease-out .03s opacity;
		opacity:0;
		@include max-screen($BIGblake){
			padding:0 40px;
		}
		@include max-screen($PCblake){
			position:relative;
			bottom:60px;
			left:60px;
			padding:80px 60px 50px;
			min-height:0;
		}
		@include max-screen($SPblake){
			width:95%;
			left:0 !important;
			right:0 !important;
			margin:auto !important;
			padding:70px 30px 30px;
			bottom:50px;
		}
		.inner{
			max-width:480px;
			width:calc(100% - 400px + 60px);
			padding:30px 0;
			opacity:0;
			transition:.4s ease-out .16s opacity;
			@include max-screen($BIGblake){
				width:calc(100% - 380px + 60px);
			}
			@include max-screen($PCblake){
				width:100%;
				max-width:none;
				padding:0;
			}
			@include max-screen($SPblake){
				
			}
			p{
				color:#ffffff;
				font-size:16px;
				line-height:160%;
				@include max-screen($BIGblake){
					font-size:15px;
				}
			}
		}
	}
	
	&.re{
		.sectionTtl{
			margin-left:auto;
			margin-right:0;
		}
		.sectionBody{
			left:0;
			right:auto;
			justify-content:flex-start;
			transform-origin:right;
		}
		.btnArea a{
			span{
				left:13px;
				right:auto;
				&:after{
					transform:rotate(-35deg);
					transform-origin:bottom left;
					right:auto;
					left:0;
				}
			}
			&:hover span{
				transform:translateX(-3px);
			}
		}
	}
	&.open{
		.sectionBody{
			transition:cubic-bezier(.3,.5,0,1) .5s transform,ease-out .15s opacity;
			transform:scaleX(1);
			opacity:1;
			.inner{
				opacity:1;
			}
		}
	}
}

.vision{
	.sectionTtl{
		&Head{
			.label{
				width:210px;
				background:#ff377d;
				@include max-screen($SPblake){
					width:150px;
				}
				span{
					width:142px;
					margin-top:6px;
					@include max-screen($SPblake){
						width:96px;
						margin-top:1px;
					}
				}
			}
			.img{
				width:183px;
				top:-40px;
				right:-6%;
				z-index:1;
				@include max-screen($SPblake){
					width:130px;
					top:-30px;
				}
			}
		}
	}
	.sectionBody{
		background:rgba(255,9,125,0.8);
	}
}

.concept{
	.sectionTtl{
		&Head{
			.label{
				width:350px;
				background:#f14c00;
				@include max-screen($SPblake){
					width:245px;
				}
				span{
					width:190px;
					margin-top:10px;
					@include max-screen($SPblake){
						width:135px;
						margin-top:2px;
					}
				}
			}
			.img{
				width:121px;
				top:200%;
				right:-7%;
				@include max-screen($SPblake){
					width:80px;
					top:330%;
				}
			}
		}
	}
	.sectionBody{
		background:rgba(241,76,0,0.8);
	}
}

.btnArea{
	text-align:center;
	margin-top:25px;
	a{
		width:190px;
		background:#ffffff;
		display:inline-block;
		font-size:14px;
		font-weight:bold;
		padding:10px 0;
		position:relative;
		cursor:pointer;
		transition:ease-out .13s background;
		@include max-screen($SPblake){
			font-size:12px;
			padding:4px 0;
			width:140px;
		}
		span{
			display:block;
			position:absolute;
			top:0;
			bottom:3px;
			right:13px;
			margin:auto;
			width:22px;
			height:8px;
			transition:ease-out .14s transform;
			@include max-screen($SPblake){
				right:6px !important;
				left:auto !important;
			}
			&:before,&:after{
				content:"";
				display:block;
				height:1px;
				background:#000000;
				position:absolute;
				margin:auto;
			}
			&:before{
				width:100%;
				left:0;
				right:0;
				bottom:0;
				@include max-screen($SPblake){
					background:none;
					width:8px;
					height:8px;
					border-right:1px solid #000000;
					border-bottom:1px solid #000000;
					transform:rotate(45deg);
					top:-3px;
				}
			}
			&:after{
				width:10px;
				transform:rotate(35deg);
				right:0;
				bottom:0;
				transform-origin:bottom right;
				@include max-screen($SPblake){
					content:none;
				}
			}
		}
		&:hover{
			background:rgba(255,255,255,0.8);
			span{
				transform:translateX(3px);
			}
		}
	}
}


footer{
	background:#070707;
	padding:18px 0;
}
.footerInner{
	width:95%;
	margin:0 auto;
}
.copyright{
	color:#ffffff;
	text-align:right;
	@include max-screen($PCblake){
		font-size:12px;
	}
}

@import "anim.scss";





